<template>
  <div>
    <div
      class="title-banner"
      :style="{ backgroundImage: 'url(' + bannerTitle.fullImage + ')'  }"
      v-for="(bannerTitle, idt) in PageTitle"
      :key="idt"
    >
      <div class="container text-center z-index-1">
        <h1>{{ bannerTitle.title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleBanner",
  props: ["PageTitle"]
};
</script>