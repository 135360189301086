<template>
  <div>
    <section class="PortfolioFilter spacer-80" :class="isLoading ? ' pb-2 ' : ' '">
      <div class="container">
        <h3 class="text-center">Find What You're Looking For</h3>
        <div class="portfolio-filters-bar pt-5">
          <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
              <form v-on:submit.prevent="searchByInput()" class="portfolio-searchInput-form">
                <div class="Search_filter portfolio-single-filter">
                  <h5>Search</h5>
                  <div class="input-group">
                    <input
                      v-model="searchInput"
                      type="text"
                      class="form-control search-input"
                      id="PortSearchInput"
                      placeholder="Search Portfolio"
                    />
                    <div class="input-group-append">
                      <button
                        v-on:click="searchByInput()"
                        type="submit"
                        class="input-group-text"
                        id="basic-addon2"
                      >
                        <img
                          src="../../assets/images/portfolio/Searchicon.svg"
                          class="img-fluid"
                          alt
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 mb-sm-0">
              <div class="Cat_filter portfolio-single-filter">
                <h5>Categories</h5>
                <div>
                  <multiselect
                    @close="searchByCategory()"
                    v-model="catValue"
                    :options="options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Choose Category"
                    label="name"
                    track-by="name"
                    :preselect-first="true"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="Sorting_filter portfolio-single-filter">
                <h5>Sorting</h5>
                <div>
                  <multiselect
                    @close="seachBySort()"
                    v-model="sortBy"
                    :options="sorting"
                    placeholder="Select"
                  ></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="row loader" v-if="isLoading">
            <h4 class="text-center">
              <img src="./../../assets/images/loader.gif" width="100" /> Please Wait. Good Stuff Loading!
            </h4>
          </div>
        </div>
      </div>
    </section>

    <div class="portfolio-items-container" v-if="!isShortVideo">
      <div
        class="portfolio-item yellow-play-btn"
        v-bind:key="index"
        v-for="(spitems, index) in SinglePortfolios"
      >
        <Portfolio v-bind:PortfolioItem="spitems" />
      </div>
    </div>
    <div class="portfolio-items-container" v-if="isShortVideo">
      <div
        class="portfolio-item yellow-play-btn"
        v-bind:key="index"
        v-for="(PortfolioItem, index) in filteredPortfolios"
      >
        <Portfolio v-bind:PortfolioItem="PortfolioItem" />
      </div>
    </div>
    <div class="text-center" v-if="isShortVideo && dataLength>8 && filteredPortfolios.length>0">
      <button class="rounded-btn see-more-btn" v-on:click="getAllPortfolio()">Show All</button>
    </div>
    <div class="text-center not-found-text" v-if="!isLoading &&  isSearch">
      <h4 class="text-center">No Portfolio Found...</h4>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
import Portfolio from "@/components/Common/Portfolio.vue";
import axios from "axios";
export default {
  props: ["SinglePortfolios"],
  components: {
    Portfolio,
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      isSearch: false,
      dataLength: 9,
      spf: [],
      isShortVideo: true,
      searchInput: "",
      catValue: [],
      sortBy: "",
      options: [],
      sorting: ["A-Z", "Z-A"]
    };
  },
  mounted() {
    this.getCategory();
  },

  computed: {
    filteredPortfolios: function() {
      const shortPortfolio = this.SinglePortfolios.slice(0, 8);
      //console.log("this.SinglePortfolios aditi rani", this.SinglePortfolios)
      return shortPortfolio;
    }
  },

  methods: {
    seachBySort() {
      this.getSearch();
    },
    searchByCategory() {
      this.getSearch();
    },
    searchByInput() {
      this.getSearch();
    },

    getAllPortfolio() {
      this.isShortVideo = false;
      console.log("this.SinglePortfolios clicked", this.SinglePortfolios);
    },

    getSearch() {     
      const data = {
        searchByInput: this.searchInput,
        searchBySort: this.sortBy,
        post_type: "portfolio",
        custom_link_field: "video_link",
        taxonomy: "portfolio_categories"
      };
      if (this.catValue) {
        data["searchByCat"] = this.catValue.reduce((acc, data) => {
          acc.push(data.slug);
          return acc;
        }, []);
      }
      //this.loader3 = this.showSpinner();
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: data
        })
        .then(res => {
          this.isLoading = false;
        //  console.log("res.data.length", res.data.customPostList.length)
          this.dataLength = res.data.customPostList.length;
          if (res.data.customPostList == 0) {
            this.isSearch = true;
          }         
          else{
            this.isSearch = false;
          }
          //this.loader3.hide();
          if (res.status == 200) {
            this.$emit("update", res.data.customPostList);
          }
        })
        .catch(err => {
          this.isLoading = false;
          //this.loader3.hide();
          console.log("error", err);
        });
    },
    getCategory() {
      this.loader4 = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/taxonomy/terms`, {
          params: { taxonomy: "portfolio_categories" }
        })
        .then(res => {
          this.loader4.hide();
          if (res.status == 200) {
            console.log("response is", res.data.terms);

            this.options = res.data.terms.reduce((acc, data) => {
              acc.push({ name: data.name, slug: data.slug });
              return acc;
            }, []);
          }
        })
        .catch(err => {
          this.loader4.hide();
          console.log("error", err.error.message);
        });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.loader {
  text-align: center;
  margin: 0 auto;
  display: block;
  padding-top: 15px;
}
.loader h4,
.not-found-text h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.not-found-text h4 {
  font-size: 22px;
}
.not-found-text {
  padding-bottom: 80px;
}
</style>