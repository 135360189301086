<template>
  <section class="quote-cta spacer-80 bg-dark">
    <div class="container" v-if="CustomWidget[1]">
      <div class="quote-container" v-html="CustomWidget[1].content" v-if="CustomWidget[1].title"></div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  props: ["CustomWidget"],
  mounted() {}
};
</script>
