<template>
  <div class="col-lg-6 mb-5 mb-lg-0">
    <div>
      <div class="resource-blog-slider-header">
        <h4>Our Blog</h4>
        <!-- <ol class="carousel-indicators">
          <li
            v-for="(slider, id) in blogPostSlider"
            :key="id"
            data-target="#carouselExampleIndicators"
            :data-slide-to="id"
            v-bind:class="{'active':(id === 0)}"
          ></li>
        </ol> -->
      </div>
      <carousel v-if="delays == true" :autoplay="false" :nav="false" :items="1" :dots="true" :loop="false" >
        <div
          v-for="(slider, id) in blogPostSlider"
          :key="id"
        >
          <div
            class="resource-blog-item z-index-1"
           v-bind:style="{'background-image': `url(${slider._embedded['wp:featuredmedia']['0'].source_url})`}"
          >
            <span class="resource-cat-ribbon">Video Editor</span>
            <h5 class="resource-blog-title">
              <a
                target="_blank"
                :href="slider.link + '?utm_source=website&utm_medium=website&utm_campaign=video'"
              >{{slider.title.rendered | truncate(60, '...')}}</a>
            </h5>
            <a
              target="_blank"
              href="https://www.grazitti.com/blog/category/design/videos/?utm_source=website&utm_medium=website&utm_campaign=video"
              class="resource-more-link"
            >
              See More
              <img src="../../../assets/images/Icons/traingle.svg" alt />
            </a>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import carousel from 'vue-owl-carousel'
import axios from "axios";
export default {
  components: {carousel},
  data() {
    return { blogPostSlider: [],  delays:false,};
  },
  mounted() {
    this.loader = this.showSpinner();
    axios
      .get("https://grazitti.com/wp-json/wp/v2/posts?_embed", {
        params: { categories: "1614", per_page: 4  }
      })
      .then(response => {
        this.loader.hide();
        this.blogPostSlider = response.data;
      })
      .catch(error => {
        console.log("error", error);
      });

axios
  .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
    params: {
      post_type: "testimonial",
      custom_text_field_one: "designation"
    }
  })
  .then(response => {
    this.loader.hide();
    this.testimonials = response.data.customPostList;
  })
  .catch(error => {
    console.log("error", error);
  });
    axios
      .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "carousel_slider"
        }
      })
      .then(response => {
        this.partners = response.data.customPostList;
        this.delays=true;
        console.log("partners==", this.partners);
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>

<style>
.resources .owl-item .resource-blog-item {
    padding: 1rem 1.5rem;
    border-radius: 8px;
    position: relative;
    border-radius: 6px;
    min-height: 230px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}
.resources .owl-item .resource-blog-item:before {
    content: "";
    background: rgba(234,82,111,.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 6px;
}
.resource-more-link {
    font-size: 11px;
    color: #fff;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 1rem 0 1.5rem;
    display: flex;
    align-items: revert;
}
.resource-more-link img{
vertical-align: initial;}
.resources .owl-carousel .owl-item img {
    width: unset!important;
    height: unset !important;
}
.resources .owl-carousel .owl-item img{
margin: unset !important;
padding-left: 6px;
}
.resources .owl-dots {
    position: absolute;
    top: -70px;
    /* left: 150px; */
    text-align: right !important;
    width: 100%;
}

.resources .owl-theme .owl-dots .owl-dot.active span {
 
    background: #ea526f !important;
    border-color: #ea526f;
   
}
.resources .owl-theme .owl-dots .owl-dot span {

    margin: 5px 3px;
  
}



.resource-blog-slider-header {
    justify-content: start !important;
    padding-left: 3px;
}

  

</style>