<template>
  <section class="resources bg-light spacer-80">
    <div class="container">
      <div class="row">
        <BlogSlider />
        <div class="col-lg-6" v-if="ResourceType === 'caseStudy'">
          <CaseStudy />
        </div>
        <div class="col-lg-6" v-else>
         <Datasheet />
        </div>
        
      </div>
    </div>
  </section>
</template>
<script>
import BlogSlider from "./BlogSlider.vue";
import Datasheet from "./Datasheet.vue";
import CaseStudy from "./CaseStudy.vue";
export default {
  props: ["ResourceType"],
  components: {
    BlogSlider,
    Datasheet,
    CaseStudy
  }
};
</script>