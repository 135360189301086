<template>
  <div class="portfolio_col">
    <div class="portfolio-front-image">
      <img :src="PortfolioItem.fullImage" :alt="PortfolioItem.title" />
    </div>
    <div class="portfolio-hover-content" v-on:click="popupVideoModal()">
      <div class="portfolio-item-text z-index-1">
        <h4 class="orange-bar" v-html="PortfolioItem.title"></h4>
        <!--p>Length: {{PortfolioItem.video_duration}}</p-->
        <!-- <p>Views: {{PortfolioItem.video_views}} views</p> -->
        <p>Industry: {{PortfolioItem.term}}</p>
        <a v-on:click="popupVideoModal()" class="play-btn">
          Play Now
          <i class="pink-round-btn"></i>
        </a>
      </div>
    </div>

    <sweet-modal ref="modal" modal-theme="dark" overlay-theme="dark">
      <div class="close" v-on:click="closePopup(PortfolioItem.id, PortfolioItem.custom_link)">Close</div>
      <iframe
        :id="'youtubeVideo-'+PortfolioItem.id"
        width="560"
        height="315"
        :src="PortfolioItem.custom_link+'?rel=0'"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <!-- <video :id="'youtubeVideo-'+PortfolioItem.id" :src="PortfolioItem.custom_link" controls>
        <source :src="PortfolioItem.custom_link" />Your browser does not support the video tag.
      </video>-->
    </sweet-modal>
  </div>
</template>
<script>
export default {
  name: "Portfolio",
  props: ["PortfolioItem"],
  methods: {
    popupVideoModal() {
      this.$refs.modal.open();
    },
    closePopup(id, src) {
      document.getElementById(`youtubeVideo-${id}`).src = "";
      setTimeout(() => {
        document.getElementById(`youtubeVideo-${id}`).src = src;
        document.body.style.overflow = "auto";
      }, 300);
      this.$refs.modal.close();
    }
  }
};
</script>
