<template>
  <div class="portfolio">
    <FixedNavbar />
    <div class="su_page_content">
      <TitleBanner v-bind:PageTitle="BannerTitle" />
      <PortfolioFilters v-bind:SinglePortfolios="Portfolios" @update="updatePortfolio" />
      <Resources />
      <QuoteCta v-if="widgetCustomHTML.length>0" v-bind:CustomWidget="widgetCustomHTML" />
    </div>
    <Footer />
  </div>
</template>

<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import TitleBanner from "@/components/Common/TitleBanner.vue";
import PortfolioFilters from "@/components/Portfolio/PortfolioFilters.vue";
import Resources from "@/components/Common/Resources/Resources.vue";
import QuoteCta from "@/components/Common/QuoteCta.vue";
import Footer from "@/components/Common/Footer.vue";
import axios from "axios";
export default {
  name: "Portfolio",
  components: {
    FixedNavbar,
    TitleBanner,
    PortfolioFilters,
    Resources,
    QuoteCta,
    Footer
  },
  data() {
    return {
      Portfolios: [],
      widgetCustomHTML: [],
      BannerTitle: []
    };
  },
  mounted() {
    this.getPortfolio();
    this.getBanner();
    this.getQuote();
    this.setMetaInfo(294)
  },
  methods: {
    updatePortfolio(data) {
      this.Portfolios = data;
    },
    getPortfolio() {
      this.loader1 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "portfolio",
            taxonomy: "portfolio_categories",
            custom_link_field: "video_link"
          }
        })
        .then(response => {
          this.loader1.hide();
          this.Portfolios = response.data.customPostList;
          console.log("Portfolios==", this.Portfolios);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getBanner() {
      this.loader2 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "portfolio-page-banner"
          }
        })
        .then(response => {
          this.loader2.hide();
          this.BannerTitle = response.data.customPostList;
          console.log("BannerTitle==", this.BannerTitle);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getQuote() {
      this.loader = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
          params: {
            type: "widget_custom_html"
          }
        })
        .then(response => {
          this.loader.hide();
          this.widgetCustomHTML = response.data.customWidgetList;
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>



