<template>
    <div class="datasheet">
      <div class="resource-blog-slider-header">
        <h4>Datasheet</h4>
      </div>
       <div v-for="(datasheet, idx) in datasheets" :key="idx">
        <div class="resource-row" v-if="datasheet.term == 'Datasheet'" >
            <div class="resource-img">
                <div class="datesheet-img">
                    <img :src="datasheet.fullImage" :alt="datasheet.title" />
                </div>
                </div>
                <div class="resource-content">
                <h5>{{datasheet.title}}</h5>
                <p>{{datasheet.content}}</p>
                <a :href="datasheet.custom_link" target="_blank" class="rounded-btn">
                    Download
                    <i class="round-play-icon"></i>
                </a>
                </div> 
        </div>
      </div>
    </div>
</template> 

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      datasheets: []
    };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios.post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "datasheet", taxonomy:'datasheet_cat',  custom_link_field: "pdf_link"
        }
      })
      .then(response => { this.loader.hide();
        this.datasheets = response.data.customPostList;
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>